const students = [
  {
    id: "8o8i6cmspc",
    name: "Bezawit Zelalem",
    course: "Digital Marketing",
    image: "/images/john_doe.jpg",
  },
  {
    id: "qa0kznvlqe",
    name: "Meraf Amare",
    course: "Digital Marketing",
    image: "/images/john_doe.jpg",
  },
  {
    id: "zmmz01xbmq",
    name: "Ephrata Biruk",
    course: "Digital Marketing",
    image: "/images/john_doe.jpg",
  },
  {
    id: "gv5rdc1esg",
    name: "Debora Mamo",
    course: "Graphics Design Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "2csselqjez",
    name: "Leul Hailu",
    course: "Graphics Design Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "au3pwylfh2",
    name: "Tekeste Negus",
    course: "Graphics Design Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "15p2ar79bj",
    name: "Eden Kasahun",
    course: "Graphics Design Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "2g50cg178j",
    name: "Natanim Andualem",
    course: "Graphics Design Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "p2o7ud5cd8",
    name: "Nigus Fikadu",
    course: "Graphics Design Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "ssm150xhmm",
    name: "Sosna Teshome",
    course: "Graphics Design Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "pbct10fhdn",
    name: "Nebiyat Dawit",
    course: "Basic Canva & Cap Cut",
    image: "/images/john_doe.jpg",
  },
  {
    id: "z630loosp3",
    name: "Besufikad Alemayehu",
    course: "Basic Canva & Cap Cut",
    image: "/images/john_doe.jpg",
  },
  {
    id: "60g2kijvjk",
    name: "ABEBA GEBRU KIFLAY",
    course: "Digital Marketing",
    image: "/images/john_doe.jpg",
  },
  {
    id: "uub13nktz9",
    name: "Dinar Kahsay",
    course: "Web Development Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "5upuul6961",
    name: "Natanim Andualem",
    course: "Web Development Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: "rgeeqzs6i3",
    name: "Heldana Mekonen",
    course: "Web Development Essentials",
    image: "/images/john_doe.jpg",
  },
  {
    id: " 5a4tdf5ehc",
    name: "Shushay Negussu",
    course: "Computer Essentials & MS Office",
    image: "/images/john_doe.jpg",
  },
  {
    id: "vocw3hadn0",
    name: "Tadesse Yeshanew",
    course: "Web Development Essentials",
    image: "/images/vocw3hadn0.jpg",
  },
];

export default students;